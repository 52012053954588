.footer {
    background-color: black;
    color: white;
    padding: 20px 0;
    text-align: center;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  
  .contact-info {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .contact-info p {
    margin: 5px 0;
  }
  
  .social-media {
    display: flex;
    gap: 15px;
    margin-bottom: 10px;
  }
  
  .icon {
    color: #fff;
    text-decoration: none;
    font-size: 21px;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 5px; /* İkon ve metin arasına boşluk */
  }
  
  .icon svg {
    color: #fff; /* İkonun rengi */
  }
  
  .copyright {
    font-size: 14px;
    color: #aaa;
  }
  
  