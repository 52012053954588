.banner-container {
  width: 100%;
  height: 35px;
  overflow: hidden;
  background-color: black;
  display: flex;
  align-items: center;
  position: relative;
  color: white;
  z-index: 2;
}

.scrolling-text {
  display: flex;
  white-space: nowrap;
  animation: scroll-text 10s linear infinite;
}

.scrolling-text span {
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  margin-right: 160px;
}

.scrolling-text:hover {
  animation-play-state: paused;
}

@keyframes scroll-text {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.menu {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: auto; /* Taşma durumunda yatay kaydırma */
  white-space: nowrap;
  position: absolute; /* Fotoğrafın üzerinde olacak şekilde konumlandırma */
  width: 100%; /* Tam genişlik */
  z-index: 1; /* Slider'ın üzerinde görünmesi için */
  background-color: rgba(255, 255, 255, 0.1);/* Daha fazla saydamlık */
  backdrop-filter: blur(5px); /* Saydamlıkla birlikte bir blur efekti */
}

.menu:hover {
  background-color: rgba(255, 255, 255, 0.8);
}

.menu-list {
  display: flex;
  justify-content: space-around;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  max-width: 1400px;
  color: black;
}

.menu-item {
  text-align: center;
  position: relative;
  padding: 0 15px;
  transition: color 0.4s ease;
  overflow: hidden;
  cursor: pointer;
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
}

.menu-item2 {
  text-align: center;
  position: relative;

  transition: color 0.4s ease;
  overflow: hidden;
  cursor: pointer;
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
}

.menu-icon2 {
  display: none;
}

.menu-list:hover {
  color: black;
}

.menu-list::before {
  content: "";
  position: absolute;
  top: -100%;
  left: 1;
  width: 100%;
  background-color: #fdeff4;
  z-index: 0;
  transition: top 0.2s ease;
}

.menu-list:hover::before {
  top: 0;
}

.menu-item:hover {
  color: rgb(216, 190, 23);
}

.menu-item:last-child:hover {
  font-size: medium;
  font-weight: bold;
}

.menu-icon {
  width: 150px; /* İstediğiniz genişliği ayarlayın */
  height: 80px; /* İstediğiniz yüksekliği ayarlayın */
}

.gizli-div{
  display: none;
}

.video-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.video-content {
  position: relative;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 800px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #000;
}


@media (max-width: 768px) {
  .menu {
    flex-direction: column;
    height: auto;
    justify-content: center;
    align-items: center;
  }

  .menu-list {
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center;
    display: none; /* Menü başlangıçta gizlenecek */
  }

  .gizli-div{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .bos2{
    width: 30px;
  }

  .menu-item {
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    text-align: left;
    width: 100%;
    border-bottom: 1px solid #ddd;
  }

  .menu-item:last-child {
    border-bottom: none;
  }

  .menu-icon {
    width: 100px;
    height: 60px;
    margin-bottom: 10px;
  }

  .menu-icon2 {
    display: flex;
    width: 100px;
    height: 60px;
  }

  
  .menu-icon2-test {
    display: none;
  }


  /* Hamburger Icon */
  .hamburger {
    display: block;
    cursor: pointer;
    z-index: 2;
  }

  .hamburger .line {
    width: 25px;
    height: 3px;
    background-color: #333;
    margin: 5px;
    transition: all 0.5s;
  }

  .menu.open .menu-list {
    display: flex; /* Menü görünür olacak */
  }

  .menu.open .hamburger .line:nth-child(1) {
    transform: rotate(45deg);
    position: relative;
    top: 8px;
  }

  .menu.open .hamburger .line:nth-child(2) {
    opacity: 0;
  }

  .menu.open .hamburger .line:nth-child(3) {
    transform: rotate(-45deg);
    position: relative;
    top: -8px;
  }
}
