.banner-container {
  width: 100%;
  height: 35px;
  overflow: hidden;
  background-color: black;
  display: flex;
  align-items: center;
  position: relative;
  color: white;
  z-index: 2;
}

.scrolling-text {
  display: flex;
  white-space: nowrap;
  animation: scroll-text 10s linear infinite;
}

.scrolling-text span {
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  margin-right: 160px;
}

.scrolling-text:hover {
  animation-play-state: paused;
}

@keyframes scroll-text {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.menu1 {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: auto; /* Taşma durumunda yatay kaydırma */
  white-space: nowrap;
  width: 100%; /* Tam genişlik */
  background-color: rgba(255, 255, 255, 0.1); /* Daha fazla saydamlık */
  backdrop-filter: blur(5px); /* Saydamlıkla birlikte bir blur efekti */
}

.menu1:hover {
  background-color: rgba(255, 255, 255, 0.8);
}

.menu-list1 {
  display: flex;
  justify-content: space-around;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  max-width: 1400px;
  color: black;
}
.menu-item1 {
  text-align: center;
  padding: 0 15px;
  transition: color 0.4s ease;
  overflow: hidden;
  cursor: pointer;
  z-index: 1;
  display: flex;
  align-items: center;
}

.menu-item22 {
  text-align: center;
  position: relative;

  transition: color 0.4s ease;
  overflow: hidden;
  cursor: pointer;
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
}

.menu-list1:hover {
  color: black;
}

.menu-list1:hover::before {
  top: 0;
}

.menu-item1:hover {
  color: rgb(216, 190, 23);
}

.menu-item1:last-child:hover {
  font-size: medium;
  font-weight: bold;
}

.menu-icon1 {
  width: 150px; /* İstediğiniz genişliği ayarlayın */
  height: 80px; /* İstediğiniz yüksekliği ayarlayın */
}

.gizli-div1 {
  display: none;
}

.menu-icon22 {
  display: none;
}

.video-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.video-content {
  position: relative;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 800px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #000;
}

@media (max-width: 768px) {
  .menu1 {
    flex-direction: column;
    height: auto;
    justify-content: center;
    align-items: center;
  }

  .menu-list1 {
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center;
    display: none; /* Menü başlangıçta gizlenecek */
  }

  .gizli-div1 {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .menu-icon22 {
    display: flex;
    width: 100px;
    height: 60px;
  }

  .menu-icon22-test {
    display: none;
  }

  .menu-item1 {
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    text-align: left;
    width: 100%;
    border-bottom: 1px solid #ddd;
  }

  .menu-item1:last-child {
    border-bottom: none;
  }

  .menu-icon1 {
    width: 100px;
    height: 60px;
    margin-bottom: 10px;
  }

  .bos{
    width: 30px;
  }

  /* Hamburger Icon */
  .hamburger1 {
    display: block;
    cursor: pointer;
    z-index: 2;
  }

  .hamburger1 .line1 {
    width: 25px;
    height: 3px;
    background-color: #333;
    margin: 5px;
    transition: all 0.5s;
  }

  .menu1.open1 .menu-list1 {
    display: flex; /* Menü görünür olacak */
  }

  .menu1.open1 .hamburger1 .line1:nth-child(1) {
    transform: rotate(45deg);
    position: relative;
    top: 8px;
  }

  .menu1.open1 .hamburger1 .line1:nth-child(2) {
    opacity: 0;
  }

  .menu1.open1 .hamburger1 .line1:nth-child(3) {
    transform: rotate(-45deg);
    position: relative;
    top: -8px;
  }
}
