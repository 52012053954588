@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;700&display=swap");

.slider-container {
  position: relative;
  width: 100%; /* Genişlik ayarı */
  margin: auto;
  overflow: hidden;
  height: 600px;
  margin-bottom: 5px;
  z-index: 0; /* Arka planda olmasını sağlar */
}

.slider-container1 {
  position: relative;
  width: 95%; /* Genişlik ayarı */
  margin: auto;
  overflow: hidden;
  height: 650px;
  margin-bottom: 5px;
  z-index: 0; /* Arka planda olmasını sağlar */
}

.image-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-image {
  width: 100%; /* Genişliği tamamen kaplayacak */
  height: 100%; /* Yüksekliği tamamen kaplayacak */
  object-fit: cover; /* Container'ı tamamen kaplamasını sağlar */
}

.prev-button,
.next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 24px;
  z-index: 2; /* Düğmelerin dots'tan öncelikli olması için */
}

.prev-button {
  left: 10px;
}

.next-button {
  right: 10px;
}

/* Dots'ları slider'ın altında göstermek için */
.navigation-dots {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 25px;
  position: relative;
  z-index: 4;
}

.dot {
  width: 20px; /* Uzunluk */
  height: 5px; /* Kalınlık */
  margin: 0 5px; /* Aralarındaki boşluk */
  background-color: #e12929;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dot.active {
  background-color: #333;
}

.content-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  height: 600px;
  margin: 0 auto; /* Ortalamak için */
  margin-bottom: 75px; /* Alt boşluk */
  background-color: #fffdec;
}

.content-container2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  height: 600px;
  margin: 0 auto; /* Ortalamak için */
  margin-bottom: 75px; /* Alt boşluk */
  background-color: #f5f5f7;
}

.text-section {
  flex: 1; /* Metin alanı eşit genişlik alır */
  padding: 20px; /* Metne boşluk ekler */
  box-sizing: border-box; /* Padding toplam genişliği etkilemez */
}

.blue {
  font-family: "Dancing Script", cursive; /* El yazısı font */
  font-style: italic; /* İtalik */
  color: #14b7e0; /* Yazı rengi */
  color: black;
}

.pink {
  font-family: "Dancing Script", cursive; /* El yazısı font */
  font-style: italic; /* İtalik */
  color: #cb11b2; /* Yazı rengi */
  color: black;
}

.image-section {
  flex: 1; /* Görsel alanı eşit genişlik alır */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Taşan içerikleri gizler */
}

.image-section img {
  width: 100%; /* Konteynerin tamamını kapla */
  height: 600px; /* Yükseklik oranını koruyarak küçült */
  object-fit: cover; /* Görseli konteynerin içine sığdır */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.image-section img:hover {
  transform: scale(1.1); /* Görseli büyütme */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); /* Gölge efekti */
  filter: grayscale(100%);
}

.comparison-slider {
  position: relative;
  width: 100%;
  height: 650px;
  overflow: hidden;
  border: 1px solid #ccc;
  cursor: ew-resize;
}

.comparison-before,
.comparison-after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.slider-handle {
  position: absolute;
  top: 0;
  width: 4px;
  height: 100%;
  background-color: #fff;
  border-left: 2px solid #000;
  border-right: 2px solid #000;
  transform: translateX(-50%);
  z-index: 2;
}
/* Dancing Script Fontu'nu dahil edin */

.italic-text {
  font-family: "Dancing Script", cursive; /* El yazısı font */
  font-size: 2.5rem; /* Metin boyutu */
  font-style: italic; /* İtalik */
}

.italic-text2 {
  font-family: "Dancing Script", cursive; /* El yazısı font */
  font-size: 2.5rem; /* Metin boyutu */
  font-style: italic; /* İtalik */
  color: #14b7e0; /* Yazı rengi */
}

.overlay {
  position: fixed;      /* Sabit pozisyon */
  top: 150px;            /* Üstten 50px mesafe */
  right: 50px;          /* Sağdan 50px mesafe */
  background-color: rgba(0, 0, 0, 0.5); /* Saydam arka plan (0.5 opaklık) */
  color: white;         /* Yazı rengi */
  width: 300px;            /* Sabit genişlik */
  height: 50%;           /* Sabit yükseklik */
  border-radius: 2px;   /* Köşeleri yuvarlak yapmak için */
  z-index: 1000;        /* Diğer öğelerin üstünde görünmesini sağlar */
  font-size: 16px;      /* Yazı boyutu */
  opacity: 1;           /* Başlangıçta görünür */
  transition: opacity 0.3s ease; /* Opaklık geçiş animasyonu */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.baslik1{
  font-family: "Dancing Script", cursive; /* El yazısı font */
  font-style: italic; /* İtalik */
  font-size: 35px;
  text-align: center;
  font-weight: bold;
}

.baslik2{
  font-size: 23px;
  text-align: center;
  padding-left: 60px;
  padding-right: 60px;
}

/* Responsive Ayarlar */
@media (max-width: 768px) {
  .overlay {
    left: 50%;
    transform: translateX(-50%); /* Yine ortalanmış olur */
    width: 90%; /* Küçük ekranlarda genişliği daha küçük yapabiliriz */
    font-size: 14px; /* Daha küçük yazı boyutu */
    top: 14%;
    width: 80%;  /* Küçük ekranlarda genişliği yüzde olarak daha küçük yapabiliriz */
    height: 200px; /* Küçük ekranlar için daha küçük yükseklik */
    font-size: 14px; /* Daha küçük yazı boyutu */
  }

  .baslik1{
    font-size: 30px;
  }

  .baslik2{
    padding-left: 30px;
    padding-right: 30px;
    font-size: 20px;
  }

  .content-container {
    flex-direction: column; /* Küçük ekranlarda üst üste gelir */
    height: auto; /* Yükseklik sınırlamasını kaldır */
  }

  .slider-container {
    height: 370px;
  }

  .slider-container1 {
    height: 350px;
  }

  .comparison-slider {
    height: 300px;
  }
  .slider-image {
    width: 100%; /* Genişliği tamamen kaplayacak */
    height: 350px; /* Yüksekliği tamamen kaplayacak */
    object-fit: cover; /* Container'ı tamamen kaplamasını sağlar */
  }
  .content-container2 {
    flex-direction: column; /* Küçük ekranlarda üst üste gelir */
    height: auto; /* Yükseklik sınırlamasını kaldır */
  }
  .text-section,
  .image-section {
    flex: unset;
    width: 100%; /* Tam genişlik kaplar */
    text-align: center; /* Mobilde yazıyı ortala */
  }
  .image-section img {
    height: auto; /* Görsel oranını koruyarak küçült */
  }
  .prev-button,
  .next-button {
    top: 70%;
  }
  .italic-text {
    font-size: 1.5rem; /* Metin boyutu */
  }

  .italic-text2 {
    font-size: 1.5rem; /* Metin boyutu */
  }
}
